import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

const Box = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Faqs = styled.div`
  word-break: break-word;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Faq = styled.div``

const FaqTitle = styled.h6`
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
  position: relative;
  padding-left: 16px;
`

const Question = styled.div`
  cursor: pointer;
  padding-left: 14px;
  position: relative;
  user-select: none;
  font-weight: 500;
  font-size: 15px;
  :first-child {
    margin-top: 16px;
  }
`

const Arrow = styled.span`
  font-weight: bold;
  font-size: ${props => (props.fontSize ? props.fontSize : "18px")};
  color: #1e1a49;
  position: absolute;
  left: 0;
  transition: 0.1s transform;
  ${props => (props.open ? `transform: rotate(90deg);` : ``)}
`

const Answer = styled.div`
  margin-bottom: 32px;
  font-weight: 300;
  padding-left: 12px;
  font-size: 14px;
  border-left: 3px solid #e7e7ec;
  margin-left: 2px;

  > :first-child {
    margin-top: 4px;
  }
`

const Separator = styled.div`
  margin: ${props => (props.marginY ? props.marginY : "32px")} 0;
  border-top: 1px solid #e7e7ec;
`

const QuestionAnswerBox = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
  margin-left: 16px;
`

const QuestionAnswer = ({ question, answer, open, setOpen }) => (
  <QuestionAnswerBox>
    <Question onClick={() => setOpen(open ? undefined : question + answer)}>
      <Arrow open={open}>{">"}</Arrow> {question}
    </Question>
    {open ? (
      <Answer>
        <ReactMarkdown>{answer}</ReactMarkdown>
      </Answer>
    ) : null}
  </QuestionAnswerBox>
)

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter, html },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/faqs-and-support.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
            faqs {
              group
              qas {
                question
                answer
              }
            }
          }
        }
      }
    }
  `)

  const [openGroup, setOpenGroup] = useState()

  return (
    <Layout meta={{ ...frontmatter.meta, noIndex: true }}>
      <div style={{ maxWidth: "1080px", margin: "auto" }}>
        <section>
          <div className="container">
            <div className="row">
              <div className="sub-page-heading">
                <h1>{frontmatter.title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div
                className="blog-text"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <br />
              <br />
              <Box>
                <Faqs>
                  {frontmatter.faqs.map(({ group, qas }) => {
                    const [open, setOpen] = useState()

                    return (
                      <div key={group}>
                        <Faq>
                          <FaqTitle
                            onClick={() => {
                              setOpenGroup(
                                openGroup === group ? undefined : group
                              )
                              setOpen(undefined)
                            }}
                          >
                            <Arrow open={openGroup === group} fontSize="20px">
                              {">"}
                            </Arrow>
                            {group}
                          </FaqTitle>
                          {openGroup === group
                            ? qas.map(({ question, answer }) => (
                                <QuestionAnswer
                                  key={question + answer}
                                  open={open === question + answer}
                                  setOpen={setOpen}
                                  question={question}
                                  answer={answer}
                                />
                              ))
                            : null}
                        </Faq>
                        <Separator marginY="22px" />
                      </div>
                    )
                  })}
                </Faqs>
                <div>
                  <h5>Need mental health support?</h5>
                  <p>
                    If you need urgent mental health support, we encourage you
                    to reach out to the following resources:
                  </p>
                  <p>
                    <strong>Lifeline —</strong>{" "}
                    <a href="tel:13 11 14">13 11 14</a> <br />
                    24/7 crisis support and suicide prevention hotline
                  </p>
                  <p>
                    <strong>Beyond Blue —</strong>{" "}
                    <a href="tel:1300 22 4636">1300 22 4636</a>
                    <br />
                    <em>
                      24/7 support for anxiety, depression, and suicide
                      prevention
                    </em>
                  </p>
                  <p>
                    <strong>
                      In an emergency, call Triple <a href="tel:000">000</a>.
                    </strong>
                  </p>
                  <Separator />
                  <p>
                    Our team at Creative Thinking Institute is available to
                    assist you with any questions you have at:
                  </p>
                  <a href="mailto:support@creativethinkinginstitute.com">
                    support@creativethinkinginstitute.com
                  </a>
                </div>
              </Box>
            </div>
          </div>
        </section>
        <br />
        <br />
        <section>
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
